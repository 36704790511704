import { bindable } from 'aurelia-framework';
export class PersistencyPercentage {
    @bindable data;
    breaks;

    dataChanged() {
        if (!this.data) return;
        this.breaks = [this.data.persistencyPercentageRequired, this.data.persistencyPercentageRequired];
        this.percentage = this.data.persistencyPercentage;
    }
}
