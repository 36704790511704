import { PLATFORM } from 'aurelia-pal';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, observable } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { Notes as NotesService } from 'services/notes';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';
import { EditNote } from './dialogs/edit-note';
PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');
PLATFORM.moduleName('./dialogs/edit-note');
import { c } from 'common/common';

export class Notes {
    static inject = [Element, EventAggregator, DialogService, NotesService];
    @bindable type;
    @bindable aboutId;
    @bindable minimal = false;
    @bindable showAdd = true;

    _element;
    _ea;
    _dialogService;
    _notes;
    note;
    canAddNote = false;
    @observable searchOn;

    noteActions = [
        { key: 'delete', name: 'delete' },
        { key: 'edit', name: 'edit' }
    ];

    _handlers = [];

    constructor(element, ea, dialogService, notes) {
        this._element = element;
        this._ea = ea;
        this._dialogService = dialogService;
        this._notes = notes;
    }

    attached() {
        this._handlers.push(this._ea.subscribe(c.EventKeys.notes.updated, (data) => {
            if (this.type !== data.type || this.aboutId !== data.aboutId) return;
            this._load();
        }));
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    typeChanged() {
        this._load();
    }

    aboutIdChanged() {
        this._load();
    }

    async _load() {
        if (!this.type || !this.aboutId) return;
        try {
            const model = await this._notes.list(this.type, this.aboutId);
            this.notes = model.notes;
            this.notes.forEach(n => n.display = true);
            this.canAddNote = model.canAddNote;
        } catch (err) {
            console.log(err);
        }
    }

    editNote(note) {
        const model = { type: this.type, aboutId: this.aboutId, note };
	    this._dialogService.open({ viewModel: EditNote, model, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (response.wasCancelled) return;
            this._dispatchUpdatedEvent();
            await this._load();
        });
    }

    _dispatchUpdatedEvent() {
        this._element.dispatchEvent(new CustomEvent('updated', { bubbles: true, detail: {} }));
    }

    async deleteNote(note) {
        const model = { key: 'delete-note', okButtonClass: 'btn-danger' };
	    this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (response.wasCancelled) return;
            try {
                await this._notes.delete(note.id);
                this._dispatchUpdatedEvent();
                await this._load();
            } catch (err) {
                console.log(err);
            }
        });
    }

    searchOnChanged() {
        try {
            if (!this.searchOn) {
                this.notes.forEach(n => n.display = true);
                return;
            }
            this.notes.forEach(n => {
                let matchesSearch = false;
                const rule = `*${this.searchOn.toLowerCase()}*`;
                if (this._matches(n.notes.toLowerCase(), rule)) matchesSearch = true;
                if (n.byMember) {
                    if (this._matches(n.byMember.fullName.toLowerCase(), rule)) matchesSearch = true;
                }
                n.display = matchesSearch;
            });
        } catch (err) {
            console.log(err);
        }
    }

    _matches(value, rule) {
        // https://stackoverflow.com/questions/26246601/wildcard-string-comparison-in-javascript
        let escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
        return new RegExp('^' + rule.split('*').map(escapeRegex).join('.*') + '$').test(value);
    }
}
