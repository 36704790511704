import { bindable } from 'aurelia-framework';
import { Calendar } from 'services/calendar';
import { I18n } from 'common/i18n';
import moment from 'moment';
import { TIME_PERIOD } from 'common/constants';

export class Notifications {
    static inject = [Calendar, I18n];
    _calendar;
    _i18n;

    @bindable view;
    @bindable timePeriod;
    @bindable notificationTypes;
    @bindable staticHeight = false;
    notifications = [];

    constructor(calendar, i18n) {
        this._calendar = calendar;
        this._i18n = i18n;
    }

    attached() {
        this._load();
    }

    viewChanged(newValue, oldValue) {
        if (!oldValue) return;
        this._load();
    }

    timePeriodChanged(newValue, oldValue) {
        if (!oldValue) return;
        this._load();
    }

    notificationTypesChanged(newValue, oldValue) {
        if (!oldValue || newValue === null) return;
        if (oldValue && newValue && oldValue === newValue) return;
        this._load();
    }

    selectTimePeriod(timePeriod, range) {
        if (this.timePeriod === TIME_PERIOD.Custom && this.customRange && this.customRange[0].isSame(range[0]) && this.customRange[1].isSame(range[1])) {
            return;
        }
        this.customRange = range;
        const loadData = this.timePeriod === timePeriod;
        this.timePeriod = timePeriod;
        if (loadData) this._load();
    }

    async _load() {
        try {
            if (!this.view || !this.timePeriod || !this.notificationTypes) return;
            this.loading = true;
            let start;
            let end;
            if (this.timePeriod === TIME_PERIOD.Custom && this.customRange) {
                start = this.customRange[0].format('l');
                end = this.customRange[1].format('l');
            }
            const notifications = await this._calendar.events(this.view, this.timePeriod, this.notificationTypes, start, end);
            notifications.forEach(n => {
                n.title = this._i18n.tr(n.titleKey);
                if (n.isAboutMember) n.aboutFullName = n.aboutMember.fullName;
                if (n.isAboutClient) n.aboutFullName = n.aboutClient.fullName;
                n.sortByDate = moment(n.date).isValid() ? moment(n.date).year(moment().year()) : n.date;
            });
            this.notifications = notifications;
            const displayTypes = this.notificationTypes ? this.notificationTypes.split(',') : [];
            const displayTypesWithCounts = [];
            displayTypes.forEach(dt => {
                const dtWithCount = {
                    type: dt,
                    count: this.notifications.filter(x => x.type === dt).length
                };
                if (dtWithCount.count > 0) displayTypesWithCounts.push(dtWithCount);
            });
            this.displayTypes = displayTypesWithCounts;
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }
}
