import { bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Onboardings } from 'services/onboardings';
import { I18n } from 'common/i18n';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export class OnboardingStatusChart {
    static inject = [Router, EventAggregator, Security, Onboardings, I18n];
    _router;
    _ea;
    _security;
    _onboardings;
    _i18n;

    @bindable data;

    CHART_TYPE = {
        Both: 'both',
        ByStatus: 'by-status',
        ByStep: 'by-step',
    };
    @bindable chartType = this.CHART_TYPE.Both;
    @bindable clickToDashboard = false;

    statusChartEl;
    stepChartEl;
    showChart = true;

    constructor(router, ea, security, onboardings, i18n) {
        this._router = router;
        this._ea = ea;
        this._security = security;
        this._onboardings = onboardings;
        this._i18n = i18n;
    }

    attached() {
        this._loadCharts();
    }

    dataChanged() {
        if (!this.data) return;
        this._loadCharts();
    }

    chartTypeChanged() {
        if (!this.chartType) return;
        this._loadCharts();
    }

    _loadCharts() {
        if (!this.data || !this.data.onboardings || !this.statusChartEl || !this.stepChartEl) return;
        this.showChart = this.data.onboardings.length > 0;
        this._loadStepChart();
        this._loadStatusChart();
    }

    _onClick = () => {
        this._router.navigate('#/members/my-team/onboarding', true);
    }

    _loadStepChart() {
        try {
            const labels = [];
            const labelsAbbr = [];
            const values = [];
            this.data.steps.forEach(step => {
                labels.push(this._i18n.tr(`onboarding-step-${step.code}`));
                labelsAbbr.push(this._i18n.tr(`onboarding-step-abbr-${step.code}`));

                let stepCount = step.count;
                if (this.data.onboardings) {
                    const displayedInStep = this.data.onboardings.filter(x => x.currentStepCode === step.code);
                    stepCount = displayedInStep.length;
                }
                values.push(stepCount);
            });

            const data = {
                labels,
                labelsAbbr,
                datasets: [
                    {
                        label: '',
                        data: values,
                        backgroundColor: this._onboardings.STEP_COLORS,
                        hoverOffset: 4
                    }
                ]
            };
            const config = {
                type: 'pie',
                data: data,
                plugins: [ChartDataLabels],
                options: {
                    plugins: {
                        legend: { display: false },
                        datalabels: {
                            color: 'white',
                            formatter: function(value, context) {
                                const count = context.dataset.data[context.dataIndex];
                                if (count === 0) return '';
                                return `${context.chart.data.labelsAbbr[context.dataIndex]} (${count})`;
                            }
                        },
                    },
                },
            };

            if (this.clickToDashboard) {
                config.options.events = ['click'];
                config.options.onClick = this._onClick;
            }

            if (this._stepChart) {
                this._stepChart.data.datasets = data.datasets;
                this._stepChart.update();
                return;
            }

            this._stepChart = new Chart(this.stepChartEl, config);
        } catch (err) {
            console.log(err);
        }
    }

    _loadStatusChart() {
        try {
            const labels = [this._i18n.tr('onboarding-status-red'), this._i18n.tr('onboarding-status-yellow'), this._i18n.tr('onboarding-status-green')];

            const countsRYG = [0, 0, 0];
            countsRYG[0] = this.data.onboardings.filter(x => x.statusColor === 'r').length;
            countsRYG[1] = this.data.onboardings.filter(x => x.statusColor === 'y').length;
            countsRYG[2] = this.data.onboardings.filter(x => x.statusColor === 'g').length;

            const data = {
                labels,
                datasets: [
                    {
                        label: '',
                        data: countsRYG,
                        backgroundColor: ['red', 'orange', 'green'],
                        hoverOffset: 4
                    }
                ]
            };
            const config = {
                type: 'pie',
                data: data,
                plugins: [ChartDataLabels],
                options: {
                    plugins: {
                        legend: { display: false },
                        datalabels: {
                            color: 'white',
                            formatter: function(value, context) {
                                const count = context.dataset.data[context.dataIndex];
                                if (count === 0) return '';
                                return `${context.chart.data.labels[context.dataIndex]} (${count})`;
                            }
                        },
                    },
                },
            };

            if (this.clickToDashboard) {
                config.options.events = ['click'];
                config.options.onClick = this._onClick;
            }

            if (this._statusChart) {
                this._statusChart.data.datasets = data.datasets;
                this._statusChart.update();
                return;
            }

            this._statusChart = new Chart(this.statusChartEl, config);
        } catch (err) {
            console.log(err);
        }
    }
}
