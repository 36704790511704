import { DialogController } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { Notes } from 'services/notes';
import { c } from 'common/common';

export class EditNote {
    static inject = [EventAggregator, DialogController, NewInstance.of(ValidationController),  Notes];
    _ea;
    dialogController;
    validationController;
    _notes;

    notes;
    
    constructor(ea, dialogController, validationController, notes) {
        this._ea = ea;
        this.dialogController = dialogController;

		this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());

		ValidationRules
            .ensure('note').required()
            .on(this);

        this._notes = notes;
    }

    activate(model) {
        this.type = model.type;
        this.aboutId = model.aboutId;
        this.titleKey = 'add-note';
        this.notes = '';
        this.id = undefined;
        if (model.note) {
            this.id = model.note.id
            this.notes = model.note.notes;
            this.titleKey = 'edit-note';
        }
    }

    async save() {
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;

            this.saving = true;
            await this._notes.save(this.id, this.type, this.aboutId, this.notes);
            this._ea.publish(c.EventKeys.notes.updated, { type: this.type, aboutId: this.aboutId });
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
        } finally {
            this.saving = false;
        }
    }
}
