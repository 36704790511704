import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Tasks } from 'services/tasks';
import { Messages } from 'services/messages';
import { ToDos } from 'services/to-dos';
import { Notifier } from 'common/ui';
import { Sites } from 'services/sites';
import { c } from 'common/common';
import { ROLE } from 'common/constants';

export class NotificationsButton {
    static inject = [EventAggregator, Security, Tasks, Messages, ToDos, Notifier, Sites];
    @bindable router = null;
    _ea;
    security;
    _tasks;
    _messages;
    _toDos;
    _notifier;
    _sites;

    showTab = 'tasks';
    notificationCount;

    _tasksInitialized;
    taskCount = 0;
    messageCount = 0;
    unopenedMessageCount = 0;
    recentMessages;
    _toDosInitialized;
    toDoCount = 0;
    triggerClass = 'btn btn-icon fw-bold';
    _triggerClassNoNotifications = 'btn btn-icon btn-light fw-bold';
    _triggerClassWithNotifications = 'btn btn-icon btn-primary fw-bold';

    showPulse = false;
    _pulseTask;
    _pulseMessage;
    _pulseToDo;

    _hasPoppedWindow;

    _handlers = [];

    constructor(ea, security, tasks, messages, toDos, notifier, sites) {
        this._ea = ea;
        this.security = security;
        this._tasks = tasks;
        this._messages = messages;
        this._toDos = toDos;
        this._notifier = notifier;
        this._sites = sites;
    }

    async attached() {
        this.theme = await this._sites.theme();
        this._handlers.push(this._ea.subscribe(c.EventKeys.loginAuthenticationUpdated, () => this._authenticationChanged()));
        this._handlers.push(this._ea.subscribe(c.EventKeys.tasks.updated, () => this._checkForTasks()));
        this._handlers.push(this._ea.subscribe(c.EventKeys.messages.updated, (data) => this._checkForMessages(data.showNotification)));
        this._handlers.push(this._ea.subscribe(c.EventKeys.toDos.todayUpdated, () => this._checkForToDos()));
        this._setNotificationCount();
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    async opened() {
        try {
            if (this.recentMessages !== undefined) return;
            const messages = await this._messages.current();
            let ct = 1;
            this.recentMessages = [];
            messages.forEach(m => {
                if (ct > 6) return;
                this.recentMessages.push(m);
            });
        } catch (err) {
            console.log(err);
        }
    }

    close() {
        this.closeMenu = new Date().getTime();
        return true;
    }

    _authenticationChanged() {
        if (this.security.isAuthenticated) return; // the bootstrapper will initiate all of the loads
        this._hasPoppedWindow = false;
        this._tasksInitialized = false;
        this._toDosInitialized = false;
    }

    _checkForTasks() {
        const newTaskCount = this._tasks.current.length;
        this._pulseTask = newTaskCount > this.taskCount
        this.taskCount = newTaskCount;
        this.recentTasks = [];
        const recentCount = this.taskCount <= 5 ? this.taskCount : 5;
        for (let i = 0; i < recentCount; i++) this.recentTasks.push(this._tasks.current[i]);
        this._tasksInitialized = true;
        this._setNotificationCount();
    }

    _checkForMessages(showNotification = true) {
        const newMessageCount = this._messages.counts?.count || 0;
        this.unopenedMessageCount = this._messages.counts?.unopened || 0;
        this._pulseMessage = showNotification && newMessageCount > this.messageCount && this.unopenedMessageCount > 0;
        this.messageCount = newMessageCount;
        this._setNotificationCount();
        this.recentMessages = undefined;
    }

    _checkForToDos() {
        const newToDoCount = this._toDos.today.length;
        this._pulseToDo = newToDoCount > this.toDoCount;
        this.toDoCount = newToDoCount;
        this.recentToDos = [];
        const recentCount = this.toDoCount <= 5 ? this.toDoCount : 5;
        for (let i = 0; i < recentCount; i++) this.recentToDos.push(this._toDos.today[i]);
        this._toDosInitialized = true;
        this._setNotificationCount();
    }

    _setNotificationCount() {
        this.notificationCount = this.unopenedMessageCount + this.taskCount + this.toDoCount;
        let triggerClass = this.notificationCount > 0 ? this._triggerClassWithNotifications : this._triggerClassNoNotifications;
        if ((this._pulseMessage || this._pulseTask || this._pulseToDo) && this.notificationCount > 0) {
            triggerClass += ' pulse pulse-white';
            this.showPulse = true;
        } else {
            this.showPulse = false;
        }
        this.triggerClass = triggerClass;

        if (this._toDosInitialized && this._tasksInitialized && !this._hasPoppedWindow) {
            this._hasPoppedWindow = true;
            if (this.taskCount + this.toDoCount > 0) {
                if (this.toDoCount > 0) this.showTab = 'todos';
                this.openWindow = new Date().valueOf();
            }
        }
    }
}
