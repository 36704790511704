import { bindable } from 'aurelia-framework';
import { Onboardings } from 'services/onboardings';

export class OnboardingProgressTracker {
    static inject = [Element, Onboardings];
    @bindable tracker;
    @bindable memberId;
    @bindable refreshMemberId;
    _element;
    steps;
    countsByStep; // contains the number in each step
    textEl;

    constructor(element, onboardings) {
        this._element = element;
        this._onboardings = onboardings;
    }

    bind() {
        if (!this.tracker && !this.memberId) return;
        if (this.memberId) return;
    }

    trackerChanged() {
        if (!this.tracker) return;
        this.countsByStep = this.tracker.countsByStep;
        this._style();
    }

    memberIdChanged() {
        if (!this.memberId) return;
        this._loadByMemberId();
    }

    refreshMemberIdChanged() {
        if (!this.refreshMemberId) return;
        this.memberIdChanged();
    }

    async attached() {
        if (this.memberId) {
            await this._loadByMemberId();
            return;
        }
        this._style();
    }

    async _loadByMemberId() {
        try {
            const data = await this._onboardings.memberStatus(this.memberId);
            this.tracker = data.tracker;
        } catch (err) {
            console.log(err);
        }
    }

    _style() {
        if (!this.tracker) return;
        this.stepNumber = this.tracker.steps.findIndex(x => !x.isComplete) + 1;
        if (this.stepNumber === 0) this.stepNumber = this.tracker.steps.length; // when everything is complete, set the stepNumber to the last number
        window.setTimeout(() => this._setBackgroundGradient(), 100);
    }

    _setBackgroundGradient() {
        try {
            const secondaryColor = getComputedStyle(document.body).getPropertyValue('--kt-primary');
            const backgroundImageStyle = `linear-gradient(to right, ${secondaryColor}, ${this.tracker.percentComplete}%, transparent)`;
            if (this.textEl) this.textEl.style.backgroundImage = backgroundImageStyle;
            let ctr = 0;
            this.tracker.steps.forEach(step => {
                ctr++;
                if (step.isComplete) return;
                if (ctr > this.stepNumber) return;
                if (ctr === this.stepNumber && step.percentComplete === 0) step.percentComplete = 10;
                if (step.percentComplete === 0) return;
                let stepBackgroundImageStyle = `linear-gradient(to right, ${secondaryColor}, ${step.percentComplete}%, transparent)`;
                const stepEl = document.getElementById(`lpfn-opt-step-${step.code}`);
                if (stepEl) stepEl.style.backgroundImage = stepBackgroundImageStyle;
            });
        } catch (err) {
            console.log(err);
        }
    }

    selectStep(code) {
        this._element.dispatchEvent(new CustomEvent('selected', { bubbles: true, detail: { code }}));
    }
}
